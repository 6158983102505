@import '../../big-direkt-de/src/bootstrap-config';
@import 'bootstrap/scss/root';
@import '@angular/cdk/overlay-prebuilt.css';

// Helpers
@import 'bootstrap/scss/helpers';

// Utilities
@import 'bootstrap/scss/utilities/api';

// Theme Components & overrides / extentions
@import '../../big-direkt-de/src/scss/components/form-group';

html {
    min-height: 100%;
}

.overflow-scroll-y {
    overflow: hidden scroll;
}

// Overwrites for big-paragraphs-holder specific styles
big-paragraphs-holder big-paragraph-text:first-child {
    margin-top: 0 !important;
}
