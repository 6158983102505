@use 'sass:math';

// Custom Variables
$letter-spacing-lg: .5px;
$letter-spacing-sm: .2px;

// Bootstrap Variables
// colors
$blue: #014785;
$secondary: #fea121;
$white: #fff;
$danger: #af3432;
$success: #32936f;
$info: $blue;
$primary: $blue;
$primary-dark: #002158;
$primary-light: #4872b5;
$secondary-dark: #c57200;
$secondary-light: #ffd257;
$dark: $primary-dark;
$light: $white;
$gray-100: #3a3a3a;
$gray-200: #9ea1ab;
$gray-300: #cfd4dd;
$gray-400: #f6f6f8;
$body-color: $gray-100;
$body-emphasis-color: $gray-100;

// text colors
$custom-text-colors: (
        'gray-100': $gray-100,
        'white': $white,
        'body': $body-color,
        'reset': inherit,
);

// background colors
$custom-background-colors: (
        'white': $white,
        'gray-400': $gray-400,
        'transparent': transparent,
);

// enable and disable features
$enable-smooth-scroll: false;
$enable-negative-margins: true;
$enable-validation-icons: false;

// gradient
$gradient-primary-start: $primary-dark;
$gradient-primary-end: $primary;
$gradient-primary-light-start: $primary;
$gradient-primary-light-end: #6980a7;
$gradient-secondary-start: $secondary;
$gradient-secondary-end: #d47e26;
$gradient-secondary-light-start: $secondary;
$gradient-secondary-light-end: #feb249;
$gradient-primary: linear-gradient(45deg, $gradient-primary-start 0%, $gradient-primary-end 100%);
$gradient-primary-light: linear-gradient(45deg, $gradient-primary-light-start 0%, $gradient-primary-light-end 100%);
$gradient-secondary: linear-gradient(45deg, $gradient-secondary-start 0%, $gradient-secondary-end 100%);
$gradient-secondary-light: linear-gradient(45deg, $gradient-secondary-light-start 0%, $gradient-secondary-light-end 100%);

// spacer
$spacer: 1rem;
$spacers: (
        0: 0,
        1: math.div($spacer, 4),
        2: math.div($spacer, 2),
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
);

// font
$font-family-ci: hind, arial, sans-serif;
$font-family-base: 'Open Sans', arial, sans-serif;
$font-size-base: .9375rem;
$line-height-base: 1.6;
$line-height-sm: 1.5;
$rfs-factor: 2.395;
$lead-font-size: 1.0625rem;
$font-weight-bold: 600;

// link
$link-color: $gray-100;
$link-hover-color: $primary-light;

// container
$container-padding-x: 1rem;
$container-max-widths: (
        sm: 510px,
        md: 690px,
        lg: 930px,
        xl: 1100px,
);

// border
$border-color: $gray-300;
$border-radius-lg: .375rem;

// headings
$headings-font-family: $font-family-ci;
$headings-font-weight: 500;
$headings-color: $primary;
$headings-line-height: 1.2;
$headings-margin-bottom: $spacer;

// nav
$nav-link-padding-x: .75rem;

// dropdown
$dropdown-link-color: $gray-100;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/maps';

// colors overwrite
$colors: map-remove($colors, 'indigo', 'purple', 'pink', 'yellow', 'teal', 'cyan');

@import 'bootstrap/scss/utilities';
@import 'scss/utilities';

// Next step towards bootstrap free frontend, yay!
$utilities: map-remove($utilities,
        "align-content",
        "align-self",
        "align",
        "background-color",
        "bg-opacity",
        "border-bottom",
        "border-color",
        "border-end",
        "border-opacity",
        "border-start",
        "border-top",
        "border-width",
        "border",
        "bottom",
        "color",
        "column-gap",
        "display",
        "end",
        "flex-direction",
        "flex-grow",
        "flex-shrink",
        "flex-wrap",
        "flex",
        "focus-ring",
        "font-family",
        "font-size",
        "font-style",
        "font-weight",
        "gap",
        "gradient",
        "height",
        "justify-content",
        "left",
        "link-offset",
        "link-opacity",
        "link-underline-opacity",
        "link-underline",
        "margin-bottom",
        "margin-end",
        "margin-start",
        "margin-top",
        "margin-x",
        "margin-y",
        "margin",
        "max-height",
        "max-width",
        "min-viewport-height",
        "min-viewport-width",
        "negative-margin-bottom",
        "negative-margin-end",
        "negative-margin-start",
        "negative-margin-top",
        "negative-margin-x",
        "negative-margin-y",
        "negative-margin",
        "object-fit",
        "opacity",
        "order",
        "overflow-x",
        "overflow-y",
        "overflow",
        "padding-bottom",
        "padding-end",
        "padding-start",
        "padding-top",
        "padding-x",
        "padding-y",
        "padding",
        "pointer-events",
        "position",
        "right",
        "rounded-bottom",
        "rounded-circle",
        "rounded-end",
        "rounded-pill",
        "rounded-start",
        "rounded-top",
        "rounded",
        "row-gap",
        "row",
        "shadow",
        "start",
        "subtle-background-color",
        "subtle-border-color",
        "text-align",
        "text-opacity",
        "text-transform",
        "top",
        "translate-middle",
        "user-select",
        "viewport-height",
        "viewport-width",
        "visibility",
        "visually-hidden",
        "white-space",
        "width",
        "z-index"
);
$h1-font-size: 2.5rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1rem;
