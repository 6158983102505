@import '../../bootstrap-config';

.form-control {
    &[readonly] {
        background: $body-bg;
        color: $gray-200;
    }

    &[disabled] {
        color: $gray-200;
    }
}
